<template>
    <section class="cardweek container mt-3 h-100">      <!-- MODIFY -->

        <div class="row h-100"> 

            <div class="col-xl-12 col-lg-12 col-md-12 grid-margin stretch-card" style="margin-bottom: 15px;" v-if='$store.state.system[cdata.system._route_].is_all_fin_for_this_page'>
                    <div  v-if='$store.state.system[cdata.system._route_].is_all_fin_for_this_page' class="w-100"> 
                        <div class="d-flex flex-column align-items-start w-100 h-100">




                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            


                            <div v-if="cdata.business_logic.card_mode==='try_solve' || cdata.business_logic.card_mode==='form_answers'" class="w-100 h-100">
                                <div class="row h-100 align-content-stretch">
                                    <div class="col-1 d-flex justify-content-end">
                                        <div v-on:click="move_to_desk('/g1/desksweek/')" class="d-flex align-items-center" style="cursor: pointer; padding: 10px; background: #F4F5FD; box-shadow: 0px 2px 0px #DCE0EA; border-radius: 12px; width: 44px; height: 44px;">
                                            <p style="font-size: 24px;"><i class="mdi mdi-close"></i></p>
                                        </div>
                                    </div>
                                    
                                    <div class="mt-5 col-10 d-flex flex-column justify-content-between">
                                        
            <!--предзадание------------------------------- -->
                                                    <div>
                                                        <p class="text-center" style="font-weight: 600; font-size: 24px;">{{ cdata.business_logic.curr_card.theme_pretask }}</p>
                                                    </div> 
                                        
                                        
                                        <div class="h-100 row d-flex justify-content-between" v-if="cdata.business_logic.show_card">
                                            <div v-for="(task_item, task_index) in this.cdata.business_logic.curr_card.task_list" :key="task_index" :class="(cdata.business_logic.curr_card.display_view == 'two_images_horisontal') ? 'my-3 col-12 col-lg-5 d-flex justify-content-center align-items-center p-0' : 'my-1 col-12 d-flex justify-content-center align-items-center'">
                                                <div class="w-100">


            <!--задание и подсказка------------------------------- -->
                                                    <div class='my-2 my-xl-4' v-if="task_item.theme_answer_format !== 'TASK_ANSWER_FORMAT_LEFT'">
                                                        <div class="row justify-content-center align-items-center">
                                                            <p class='offset-2 col-8 task_text text-center'>{{task_item.task_text}}</p>
                                                            <div v-if='task_item.theme_hint_image!=="" || task_item.theme_hint_audio!==""' class='col-2'>
                                                                <div class="tips_btn_outer">
                                                                    <!--<b-button   class="btn-fw"   variant="light" style=" min-width: unset;"     v-bind:theme_hint_image="task_item.theme_hint_image" v-bind:theme_hint_audio="task_item.theme_hint_audio"    v-on:click="show_tips" ><i class="mdi mdi-magnify"></i></b-button>--> 
                                                                    <p class="tips_btn" :theme_hint_image="task_item.theme_hint_image" :theme_hint_audio="task_item.theme_hint_audio" @click="show_tips"><i :theme_hint_image="task_item.theme_hint_image" :theme_hint_audio="task_item.theme_hint_audio" @click="show_tips" class="mdi mdi-magnify"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

            <!--картинка------------------------------- -->
                                                    <div v-if="!(task_item.task_img==='') && cdata.business_logic.curr_card.display_view == 'one_image'" class='d-flex justify-content-center my-4 one_image_container'>
                                                         
                                                              <img :src="cdata.system.cnf['server_cloud']+task_item.task_img">
                                                    </div>
            
                                                    <div v-else-if="!(task_item.task_img==='')" :class="(cdata.business_logic.curr_card.display_view == 'two_images_horisontal') ? 'd-flex justify-content-center my-2 my-xl-4 two_images_horisontal_container' : 'd-flex justify-content-center my-2 my-xl-4 images_container'">
                                                            <!--:style="[(cdata.business_logic.curr_card.display_view == 'two_images_horisontal') ? {'height': '320px'} : {'height': '120px'}]"-->
                                                              <img v-if="cdata.business_logic.curr_card.display_view == 'two_images_horisontal'" style="width:100%" :src="cdata.system.cnf['server_cloud']+task_item.task_img"> 
                                                              <!--width: 445px; max-height:320px;-->
                                                              
                                                              <img v-else :src="cdata.system.cnf['server_cloud']+task_item.task_img" style="max-height: 120px; width: 100%;">
                                                              
                                                              
<!--                                                              <object v-if="cdata.business_logic.curr_card.display_view == 'two_images_horisontal'"  type="image/svg+xml" style="width: 445px; max-height:320px;" v-bind:data="cdata.system.cnf['server_cloud']+task_item.task_img"  ></object>
                                                              <object v-else type="image/svg+xml" style="max-height: 120px;" v-bind:data="cdata.system.cnf['server_cloud']+task_item.task_img"  ></object>-->
                                                    </div>

            <!--озвучка------------------------------- -->
                                                    <audio  v-if="!(task_item.task_sound==='')"  controls  > 
                                                        <source v-bind:src="cdata.system.cnf['server_cloud']+task_item.task_sound" type="audio/mp3">
                                                    </audio>


            <!--my-4-->
                                                    <div v-if='!task_item.is_buttons' class="">
                                                            <div v-if="task_item.theme_answer_format == 'TASK_ANSWER_FORMAT_LEFT'" class="text-center d-flex align-items-center justify-content-center"> 
                                                                <span class="task_text task_text_left mr-2">{{task_item.task_text}}</span>
                                                               
                                                                <div class="d-flex" style="position: relative;">
                                                                    <input autocomplete="off" :disabled="(cdata.business_logic.card_mode==='form_answers')" :style="((cdata.business_logic.card_mode==='form_answers') ? (answer_check[task_item.task_id]['style_for_input']) : '')" class="input_text_answer" :id="'input_text_answer_' + task_index" v-model="cdata.business_logic.curr_card.task_list[task_index].task_input" v-on:keyup.enter="focus_next_input"  :tabindex="parseInt(task_index,10)+1" :key="task_item.task_id" v-bind:task_id="task_item.task_id" v-bind:index="task_index"/>
                                                                    
            <!--подсказка------------------------------- -->
                                                        <div style="position: absolute; right: -50px;" class="mx-3" v-if='task_item.theme_hint_image!=="" || task_item.theme_hint_audio!==""'>
                                                            <p class="tips_btn" :theme_hint_image="task_item.theme_hint_image" :theme_hint_audio="task_item.theme_hint_audio" @click="show_tips"><i :theme_hint_image="task_item.theme_hint_image" :theme_hint_audio="task_item.theme_hint_audio" @click="show_tips" class="mdi mdi-magnify"></i></p>
                                                        </div>
                                                                    
                                                                    <div class='ml-2 input_correct_answer' :id="'input_correct_answer_' + task_index" :style="((cdata.business_logic.card_mode==='form_answers') ? (answer_check[task_item.task_id]['show_correct_answer'] + 'position: absolute;') : '')">
                                                                        <span>Correct answer: {{task_item.task_answer}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div v-else class="d-flex flex-column align-items-center">
                                                                <input autocomplete="off" :disabled="(cdata.business_logic.card_mode==='form_answers')" :style="((cdata.business_logic.card_mode==='form_answers') ? (answer_check[task_item.task_id]['style_for_input']) : '')" class="input_text_answer text-center" :id="'input_text_answer_' + task_index"  v-model="cdata.business_logic.curr_card.task_list[task_index].task_input" v-on:keyup.enter="focus_next_input"  :tabindex="parseInt(task_index,10)+1" :key="task_item.task_id" v-bind:task_id="task_item.task_id" v-bind:index="task_index"/>
                                                                
                                                                <div class='mt-2 input_correct_answer' :id="'input_correct_answer_' + task_index" :style="((cdata.business_logic.card_mode==='form_answers') ? (answer_check[task_item.task_id]['show_correct_answer']) : '')">
                                                                    <p>Correct answer: {{task_item.task_answer}}</p>
                                                                </div>
                                                            </div>
                                                        
                                                    </div>

<!--TWO IMAGES-->
                                                    <div v-if='(task_item.is_buttons) && (!task_item.is_multi_buttons) && (cdata.business_logic.curr_card.display_view != "default" && cdata.business_logic.curr_card.display_view != "one_image" && cdata.business_logic.curr_card.display_view != "two_images_vertical")' :class="(cdata.business_logic.card_mode==='form_answers') ? 'noselect my-2 my-xl-4 w-100' : 'my-2 my-xl-4 w-100'">

                                                        <div class="d-flex justify-content-around">
                                                            <div v-for="(variant_item, variant_index) in task_item.variansts_for_answer" :key="variant_index">
                                                                <input class="checkbox-tools" type="radio" :name="'radios_task_index_'+task_index" :id="'radios_task_index_'+task_index+'_'+variant_index" v-model="task_item.selected_radio" :value="variant_index" >
                                                                <label class="for-checkbox-tools" :for="'radios_task_index_'+task_index+'_'+variant_index" :style="((cdata.business_logic.card_mode==='form_answers') ? (answer_check[task_item.task_id][variant_index]['answer_check_style']) : '')">
                                                                        {{ variant_item.value }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
            
            
                                                    <div v-if='(task_item.is_buttons) && (!task_item.is_multi_buttons) && (cdata.business_logic.curr_card.display_view == "default" || cdata.business_logic.curr_card.display_view == "one_image" || cdata.business_logic.curr_card.display_view == "two_images_vertical")' :class="(cdata.business_logic.card_mode==='form_answers') ? 'noselect my-2 my-xl-4 d-flex justify-content-center' : 'my-2 my-xl-4 d-flex justify-content-center'">

                                                        <div class="row">
                                                            <div :class="'d-flex justify-content-center align-items-center col-' + (12 / (Object.keys(task_item.variansts_for_answer).length))" v-for="(variant_item, variant_index) in task_item.variansts_for_answer" :key="variant_index">
                                                                <input class="checkbox-tools" type="radio" :name="'radios_task_index_'+task_index" :id="'radios_task_index_'+task_index+'_'+variant_index" v-model="task_item.selected_radio" :value="variant_index" >
                                                                <label class="for-checkbox-tools" :for="'radios_task_index_'+task_index+'_'+variant_index" :style="((cdata.business_logic.card_mode==='form_answers') ? (answer_check[task_item.task_id][variant_index]['answer_check_style']) : '')">
                                                                        {{ variant_item.value }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
            
            

                                                    <div v-if='task_item.is_buttons && task_item.is_multi_buttons' :class="(cdata.business_logic.card_mode==='form_answers') ? 'noselect my-4 d-flex justify-content-center' : 'my-4 d-flex justify-content-center'">


                                                        <div class="row">
                                                            <div :class="'d-flex justify-content-center align-items-center col-' + (12 / (Object.keys(task_item.variansts_for_answer).length))" v-for="(variant_item, variant_index) in task_item.variansts_for_answer" :key="variant_index">
                                                                <input class="checkbox-tools" type="checkbox" :name="'checkbox_task_index_'+task_index" :id="'checkbox_task_index_'+task_index+'_'+variant_index"  v-model="task_item.selected_ckeckbox" :value="variant_index" >
                                                                <label class="for-checkbox-tools" :for="'checkbox_task_index_'+task_index+'_'+variant_index" :style="((cdata.business_logic.card_mode==='form_answers') ? (answer_check[task_item.task_id][variant_index]['answer_check_style']) : '')">
                                                                        {{ variant_item.value }}
                                                                </label>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
            
            
            
                                        <div class="bottom_button_section d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center" :style="cdata.business_logic.show_answer_style">
                                            <div v-if="cdata.business_logic.card_mode==='form_answers'">
                                                    <img v-if="cdata.business_logic.curr_card_is_solved"
                                                         src="/resource/img/correct_solved_card_atom.svg" alt="" style="width: 200px; height: 90px;">
                                                    <img v-else-if="!cdata.business_logic.curr_card_is_solved"
                                                         src="/resource/img/incorrect_solved_card_atom.svg" alt="" style="width: 200px; height: 90px;">
                                            </div>
                                            <div>
                                                <button v-if="cdata.business_logic.card_mode==='try_solve'" class="btn skipbutton" variant="light" style="width: 216px; padding: 10px 70px; font-size: 20px; background: #F4F5FD; box-shadow: 0px 4px 0px #DCE0EA; border-radius: 12px;"   :tabindex="this.cdata.business_logic.curr_card.task_cnt" :disabled="cdata.system.buttons.common_without_symmary.is_button_blocked || cdata.business_logic.card_mode==='form_answers'"     v-on:click="slide_card" >Skip</button> 
                                                <div v-else-if="cdata.business_logic.card_mode==='form_answers' && cdata.business_logic.curr_card_is_solved" class="d-flex flex-column result_test_correct">
                                                     Well done!
                                                    <div class="d-flex justify-content-center">
                                                        <span class="d-flex justify-content-center align-items-center ml-1" v-if="Number(cdata.business_logic.card_gold) > 0">+{{cdata.business_logic.card_gold}}<img src="/resource/img/game_ico/gold.svg" alt="image" class="ml-1"/></span>
                                                        <span class="d-flex justify-content-center align-items-center ml-1" v-if="Number(cdata.business_logic.card_exp) > 0">+{{cdata.business_logic.card_exp}}<img src="/resource/img/game_ico/exp.svg" alt="image" class="ml-1"/></span>
                                                        <span class="d-flex justify-content-center align-items-center ml-1" v-if="Number(cdata.business_logic.card_joker) > 0">+{{cdata.business_logic.card_joker}}<img src="/resource/img/game_ico/joker_coin.svg" alt="image" class="ml-1"/></span>
                                                    </div>
                                                </div>
                                                <p v-else-if="cdata.business_logic.card_mode==='form_answers' && !cdata.business_logic.curr_card_is_solved" class="result_test_incorrect">
                                                    Look at the right answers! <br>
                                                    Don’t worry and go on
                                                </p>
                                            </div>
                                            <div>
                                                <button v-if="cdata.business_logic.card_mode==='try_solve'" class="btn solvebutton"   variant="primary" style="width: 216px; padding: 10px 35px; font-size: 20px; color: #FFFFFF; background: #55D18E; box-shadow: 0px 4px 0px #36B872; border-radius: 12px;"   :tabindex="this.cdata.business_logic.curr_card.task_cnt" :disabled="cdata.system.buttons.common_without_symmary.is_button_blocked"     v-on:click="show_answers" >Check answers</button> 
                                                <button v-else-if="cdata.business_logic.card_mode==='form_answers'" class="btn" variant="primary" style="width: 216px; padding: 10px 60px; font-size: 20px; color: #FFFFFF; background: #49BDF4; box-shadow: 0px 4px 0px #319CCF; border-radius: 12px;" v-on:click="next_card" :tabindex="0" :disabled="cdata.system.buttons.common_without_symmary.is_button_blocked">Next card</button>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                    <div class="col-1"></div>
                                </div>
                            </div>
                            
                            
                            
                            
                            
                      
                            
<!--                          buttons 
            display: flex;
            flex-direction: column;
            align-items: flex-end;-->
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
<!--                            <div v-if="cdata.business_logic.card_mode==='form_answers'">

                                <div  class="pt-3" v-for="(task_item, task_index) in this.cdata.business_logic.curr_card.task_list" :key="task_index">
                                    <div class="pt-1"> 

                                        <div>
                                            <b> {{ task_item.theme_pretask }} </b>
                                        </div>  


                                        <div v-if="!(task_item.task_img==='')" style="min-width:500px;">
                                            <object type="image/svg+xml" width='250px' v-bind:data="cdata.system.cnf['server_cloud']+task_item.task_img+'?t=' + $root._SYS().add_v()"  ></object>
                                        </div>

                                        <audio  v-if="!(task_item.task_sound==='')"  controls  > 
                                            <source v-bind:src="cdata.system.cnf['server_cloud']+task_item.task_sound+'?t=' + $root._SYS().add_v()" type="audio/mp3">

                                        </audio>

 
                                        <div v-if="!task_item.is_buttons" v-bind:class="(task_item.is_task_answer_correct?'text-primary':'text-danger')">
                                            
                                            {{ task_item.is_task_answer_correct?"ВЕРНО":"НКВЕРНО" }}| {{task_item.task_text}} ~~~ {{ task_item.task_input }} ~?~ {{  task_item.task_answer }}
                                        </div>

                                        <div v-if="task_item.is_buttons && !task_item.is_multi_buttons" v-bind:class="(task_item.is_task_answer_correct?'text-primary':'text-danger')">
                                            {{ task_item.is_task_answer_correct?"ВЕРНО":"НКВЕРНО" }}| {{task_item.task_text}} ~~~ {{  task_item.variansts_for_answer[task_item.selected_radio].value }} ~?~ {{  task_item.task_answer.split("#")[1] }}
                                        </div>

                                        <div v-if="task_item.is_buttons && task_item.is_multi_buttons"  v-bind:class="(task_item.is_task_answer_correct?'text-primary':'text-danger')">

                                            {{ task_item.is_task_answer_correct?"ВЕРНО":"НКВЕРНО" }}|

                                            {{task_item.task_text}} ~~~ 
                                            <div   v-for="(cbx_item, cbx_index) in task_item.selected_ckeckbox" :key="'user_answ'+cbx_index" >
                                                {{ task_item.variansts_for_answer[cbx_item].value  }}  {{ cbx_index  }}  && 
                                            </div> 
                                            ~?~  

                                            <div   v-for="(answ_item, answ_index) in task_item.task_answer.split('#')[1].split('&&')" :key="'task_answ'+answ_index" >
                                                {{ answ_item  }} {{ answ_index  }} && 
                                            </div> 


                                        </div>

                                    </div> 
                                </div>




                                <b-button v-on:click="move_to_desk('/g1/desksweek/')"  class="btn-fw ml-4 mb-2 mt-3"   variant="warning" style=" min-width: unset;"   :tabindex="1" :disabled="cdata.system.buttons.common_without_symmary.is_button_blocked"      >Назад к колодам</b-button> 

                                <b-button v-on:click="next_card"                       class="btn-fw ml-4 mb-2 mt-3"   variant="primary" style=" min-width: unset;"   :tabindex="0" :disabled="cdata.system.buttons.common_without_symmary.is_button_blocked"      >Следующую карточку!</b-button> 

                            </div>-->
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            


                            <div v-if="cdata.business_logic.card_mode==='form_end_cards'">
                                <p>You've reached the end. Good job!</p>
                                <router-link class="" v-bind:to="'/g1/desksweek'+'?t='+$root._SYS().add_v()"  >Go Back</router-link>
                            </div>

                            <div v-if="cdata.business_logic.card_mode==='wait_screen'">
                                <p>Loading wait...</p>
                            </div>




                                
<!-- TIP Modal -->
<transition name="pop" appear>
<div @click.self="close_tips" v-if="cdata.business_logic.show_tips" class="custom-modal d-flex justify-content-center align-items-center" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div @click.stop="" class="d-flex justify-content-center align-items-center" role="document" style="width: fit-content;">
        <div class="modal-content border-0" style="border-radius: 24px">
    <div class="modal-header border-0 d-flex justify-content-end pb-0" style="background: transparent;">
                                        <div v-on:click="close_tips" class="d-flex align-items-center" style="cursor: pointer; padding: 10px; background: rgb(234 234 245); box-shadow: 0px 2px 0px #DCE0EA; border-radius: 12px; width: 44px; height: 44px;">
                                            <p style="font-size: 24px;"><i class="mdi mdi-close"></i></p>
                                        </div>
    </div>
            <div class="modal-body d-flex flex-column align-items-center pt-0">


                                      <div v-if="!(cdata.business_logic.tips.theme_hint_image==='')" class="d-flex justify-content-center align-items-center px-5 pb-5">
                                          <!--<object type="image/svg+xml" v-bind:data="cdata.system.cnf['server_cloud']+cdata.business_logic.tips.theme_hint_image" class="w-100" ></object>-->
                                          <img class="responsive_image_workaround" :src="cdata.system.cnf['server_cloud']+cdata.business_logic.tips.theme_hint_image">
                                           <!--style="min-width:600px;"-->
                                      </div>

<!--                                      <audio class="mt-3"  v-if="!(cdata.business_logic.tips.theme_hint_audio==='')"  controls> 
                                          <source v-bind:src="cdata.system.cnf['server_cloud']+cdata.business_logic.tips.theme_hint_audio" type="audio/mp3">
                                      </audio>-->


            </div>
        </div>
  </div>
</div> 
</transition>

                                
                                




                         


                        </div>
<!--v-if="!state.desks_limit[form.current_deskweek]['block_by_limit']"-->
<!--                        <div v-else>
                                        <div v-on:click="move_to_desk('/g1/desksweek/')" class="d-flex align-items-center" style="cursor: pointer; padding: 10px; background: rgb(234 234 245); box-shadow: 0px 2px 0px #DCE0EA; border-radius: 12px; width: 44px; height: 44px;">
                                            <p style="font-size: 24px;"><i class="mdi mdi-close"></i></p>
                                        </div>
                            you reached the daily limit <br>
                            come back tomorrow
                        </div>-->

                    </div>







            </div>   

        </div>





    </section>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
            var cdata = {//Если не указать - не всключиться реактивность 
                /* COPYPASTE MODIFY */
                system: {
                    _route_: "g1_cardweek", /* MODIFY */
                    is_section: {// store/index  -> is_required_sections
                        center: false, /* СЕКЦИИ НА СТРАНИЦЕ MODIFY  v-if='cdata.system.is_section.center'   */
                    },
                    is_stage: {// store/index  -> is_required_stages
                        center: false,
                    },
                    cnf: {},
                    buttons: {
                        common: {
                            is_button_blocked: true,
                            button_blocking_exeption_list: {}, //ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                        },
                        common_without_symmary: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        //0: "r_auth", //r_auth  
                                        0: "json_version", //r_auth 
                                        1: "events_list", //r_auth 
                                        //2: "json_layout_xxxx__pos_xx", НЕТ, просто не добавлять на эту страницу динамические таблицы!!!
                                    }
                        },
                        button1: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        0: "r_auth", //r_auth 
                                        1: "a_test", //r_auth 
                                        2: "json_layouts", //r_auth 
                                        //3: "json_layout_xxxx__pos_xx", НЕТ, просто не добавлять на эту страницу динамические таблицы!!!
                                    }
                        },

                    },

                },
                errors: {
                    is_errors: false,
                    actions_error: {},
                },
                business_logic: {
                    desk_version: "-1",
                    pos: "-1",
                    lid: "-1",
                    desk: {},
                    curr_card_is_solved: false,
                    curr_card_is_skiped: false,
                    curr_card: {
                        card_num:"-1",
                        stopochka_num:"-1"
                    },
                    card_mode: "try_solve",
                    card_exp: "0",
                    card_gold: "0",
                    card_joker: "0",
                    
                    show_card: false,
                    show_tips: false,
                    show_answer_style: "",
                },

            }

    export default {
        name: 'cardweek', /* MODIFY */
        data() {
            return {
                cdata: cdata,
            };
        },

        created() { },

        mounted() {
            document.getElementById('await_loader_outer').style.display = 'block';
            this.$store.dispatch('_system/vuex_set__route_', this.cdata.system._route_);
            this.$root._PAGE().on_mounted(this.$store, this.cdata); //обнулить флажки


            this.cdata.business_logic.card_mode = "try_solve";
            this.cdata.business_logic.curr_card_is_solved = false;
            this.cdata.business_logic.curr_card_is_skiped = false;
            this.cdata.business_logic.show_answer_style = "";
            //IF NEEDED 

            //SAVE current_deskweek to LOCAL STORAGE
            if (window._STR().is_empty(this.$store.state.current_deskweek)) {
                let current_deskweek = localStorage.getItem("current_deskweek");
                if (window._STR().is_empty(current_deskweek))
                {
                    this.$root._PAGE().move_to_desk(this.$store, this.cdata, '/g1/desksweek/');
                } else
                {
                    this.$store.dispatch('_business_logic/vuex_set__current_deskweek', {current_deskweek: current_deskweek});
                }
            } else
            {
                localStorage.setItem("current_deskweek", this.$store.state.current_deskweek);
            }
            //IF NEEDED 

            setTimeout(() => {
                this.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': true, '_route_': this.cdata.system._route_ });

                this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            }, 300);
            
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___global__level_settings__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__exp_by_tasks__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__gold_by_tasks__back(this.$store, this.cdata));
             this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__solve_limit__back(this.$store, this.cdata));
        },
        computed: {

            /* COPYPASTE */
            ...mapGetters(['__blocked_tables', '__is_required_sections', '__is_required_stages', '__tables', '__is_errors']),
            _system() {
                let debug = {};
                try {
                    debug['tables'] = this.__tables(this.cdata['system']['_route_']);
                    debug['is_errors'] = this.__is_errors(this.cdata['system']['_route_']);
                    debug['blockings'] = this.__blocked_tables(this.cdata['system']['_route_']);
                    debug['sections'] = this.__is_required_sections(this.cdata['system']['_route_']);
                    debug['stages'] = this.__is_required_stages(this.cdata['system']['_route_']);
                } catch (ex) {
                    console.log(ex);
                }
                return debug;
            },
            /* MODIFY */
            state() {
                let state = {};
                try {
                    /* MODIFY */ //ВЫБРАТЬ используемые МОДУЛИ --> подгрузятся ТАБЛИЦЫ в них
                    // state['test'] = this.$store.state['state_tables'].state_test;/* MODIFY */
                    state['auth'] = this.$store.state['state_tables'].state_auth;/* MODIFY */
                    state['layouts'] = this.$store.state['state_tables'].state_layouts;/* MODIFY */
                    state['rg_for_task'] = this.$store.state['state_tables'].state_rg;/* MODIFY */
                    state['st_for_task'] = this.$store.state['state_tables'].state_st;/* MODIFY */
                    state['desks'] = this.$root._LAYOUT().add_desks_to_state_desks(this.$store, this.cdata, this.$store.state.is_loaded_stages.dynamic_desks_ready);

                    state['desks_limit'] = this.$root._LAYOUT().add_limit_to_state_desks_limit(this.$store, this.cdata, this.$store.state.is_loaded_stages.level_props__solve_limit);
                    
                    state['lvl_info'] = this.$root._UPDATE().current_player_lvl(this.$store, this.cdata, this.$store.state.is_loaded_stages.global__level_settings);
                    
                    state['player_inventory'] = this.$root._LAYOUT().sort_player_inventory(this.$store, this.cdata, this.$store.state.is_loaded_stages.player_inventory);
                    
                    state['player_current_profession'] = this.$root._LAYOUT().get_player_current_profession(this.$store, this.cdata, this.$store.state.is_loaded_stages.global__profession_list, this.$store.state.is_loaded_stages.player_professions);
                    
                    state['quest_manager'] = this.$root._LAYOUT().form_quest_manager(this.$store, this.cdata, this.$store.state.is_loaded_stages.quest_manager, this.$store.state.is_loaded_stages.global__inventory_glossary);

                } catch (ex) {
                    console.log(ex);
                }

                return state;
            },

            form() {
                let form = {};// form.classnum


                form['cnt_loaded'] = this.$store.state.cnt_loaded;
                form['is_all_stage_fin'] = this.$store.state.is_all_stage_fin;
                form['classnum'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('classnum') ? this.$store.state.state_tables.state_auth.r_auth['classnum'] : "-1";
                form['lid'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('lid') ? this.$store.state.state_tables.state_auth.r_auth['lid'] : "-1";
                form['current_deskweek'] = this.$store.state.current_deskweek;
                
                return form;
            },
            /* #ODIFY */
            /* #OPYPASTE */
            
            answer_check() {
                let result = {};
                
                for (const [task_index, task] of Object.entries(this.cdata.business_logic.curr_card.task_list)) {
                    result[task.task_id] = {};
                    if(task.is_buttons && task.is_multi_buttons){
                            let checkbox_correct_answers = task.task_answer.split("#")[1].split("&&");
                            let variansts_for_answer = task['task_answer'].split("#")[0].split("|");
                            result[task.task_id] = {};
                            for (let j = 0, len = variansts_for_answer.length; j < len; j++) {
                                let is_chosen = task.selected_ckeckbox.find(variant => variant == j);
                                let is_correct = checkbox_correct_answers.find(variant => variant == variansts_for_answer[j]);                                
                                result[task.task_id][j] = {
                                    value: window._STR().clean(variansts_for_answer[j]),
                                    index: j.toString(),
                                    correct: ((is_correct) ? true : false),
                                    chosen: ((is_chosen) ? true : false),
                                    answer_check_style: "",
                                };
                                
                                if(result[task.task_id][j]['correct'] && result[task.task_id][j]['chosen']) { result[task.task_id][j]['answer_check_style'] = "color: #55D18E; background: #C7FFC2; border: 2px solid #55D18E"; }
                                else if(result[task.task_id][j]['correct'] && !result[task.task_id][j]['chosen']) { result[task.task_id][j]['answer_check_style'] = "border: 2px solid #55D18E"; }
                                else if(!result[task.task_id][j]['correct'] && result[task.task_id][j]['chosen']) { result[task.task_id][j]['answer_check_style'] = "color: #FF7697; background: #FFE3E3; border: 2px solid #FF7697"; }
                            }
                    } else if(task.is_buttons && !task.is_multi_buttons){
                            let radio_correct_answer = task.task_answer.split("#")[1];
                            let variansts_for_answer = task['task_answer'].split("#")[0].split("|");
                            let chosen_one = task.selected_radio;
                            result[task.task_id] = {};
                            for (let j = 0, len = variansts_for_answer.length; j < len; j++) {                          
                                result[task.task_id][j] = {
                                    value: window._STR().clean(variansts_for_answer[j]),
                                    index: j.toString(),
                                    correct: ((variansts_for_answer[j] ==  radio_correct_answer) ? true : false),
                                    chosen: ((chosen_one != "" && Number(chosen_one) === j) ? true : false),
                                    answer_check_style: "",
                                };

                                if(result[task.task_id][j]['correct'] && result[task.task_id][j]['chosen']) { result[task.task_id][j]['answer_check_style'] = "color: #55D18E; background: #C7FFC2; border: 2px solid #55D18E"; }
                                else if(result[task.task_id][j]['correct'] && !result[task.task_id][j]['chosen']) { result[task.task_id][j]['answer_check_style'] = "border: 2px solid #55D18E"; }
                                else if(!result[task.task_id][j]['correct'] && result[task.task_id][j]['chosen']) { result[task.task_id][j]['answer_check_style'] = "color: #FF7697; background: #FFE3E3; border: 2px solid #FF7697"; }
                            }
                    } else if(!task.is_buttons) {
                        result[task.task_id] = {};
                        result[task.task_id]["style_for_input"] = "";
                        result[task.task_id]["show_correct_answer"] = "";
                        
                        if(task.task_answer == task.task_input) { result[task.task_id]["style_for_input"] = "color: #2E3B4B; background: #C7FFC2; border: 2px solid #55D18E;"; }
                        else if(task.task_answer != task.task_input) {
                            result[task.task_id]["style_for_input"] = "color: #FF7697; border: 2px solid #FF7697;";
                            result[task.task_id]["show_correct_answer"] = "display: flex; right: -220px; top: 10%;";
                        }
                    }
                }
                
                return result;
            }
        },

        watch: {

            /* COPYPASTE на каждое изменение при работе с таблицей два вызова на начало и завершение*/
            "form.cnt_loaded": {
                handler: function () {



                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после выполнения всех стадий, на любое действие с таблицами
                    if (this.$store.state.is_all_stage_fin)
                    {
                        document.getElementById('await_loader_outer').style.display = 'none';
                        //console.log("EXECUTE AFTER ALL STAGE LOAD FIN [" + this.cdata.system._route_ + "]");
                    }


                    //ВЫПОЛНИТСЯ РАЗОВО - проверяем готовы ли стадии, для текущей страницы 
                    if (this.$store.state.system[this.cdata.system._route_].is_mounted_execute && !this.$store.state.system[cdata.system._route_].is_all_fin_for_this_page) {
                        //проверить завершение всех стадий для страницы (срабатывал раньше времени)
                        //set cdata.system.is_stages_fin_for_THIS_page
                        // console.log("CALC is_stages_fin_for_THIS_page");
                        if (this.$root._SYS().check_is_stages_fin_for_this_page(this.$store, this.cdata))
                        {
                             
                            if (
                                    this.$store.state.is_loaded_stages.dynamic_desks_ready === "fin"  
                                    ) {
                                //загрузим стопочки в business_logic
                                let child_lid = this.$store.state.state_tables.state_auth.r_auth.lid;
                                let child_classnum = this.$store.state.state_tables.state_auth.r_auth.classnum;
                                
                                let pos00 = window._STR().pos00(this.$store.state.current_deskweek);
                                let lid0000 = window._STR().lid0000(this.$store.state.current_deskweek);
                                this.cdata.business_logic['desk'] =  this.$store.state.state_tables.state_desks['json_layout_' + lid0000 + '__pos_' + pos00].desk ;
                                this.cdata.business_logic['desk_name'] = 'json_layout_' + lid0000 + '__pos_' + pos00;
                                this.cdata.business_logic['desk_theme_list'] = this.$store.state.state_tables.state_desks['json_layout_' + lid0000 + '__pos_' + pos00].theme_list ;
                                //this.cdata.business_logic['rg_learning'] собирается ниже! (добавляется лимит!)

                                this.cdata.business_logic['desk_version'] = this.$store.state.state_tables.state_desks['json_layout_' + lid0000 + '__pos_' + pos00].version_timestamp;
                                this.cdata.business_logic['pos'] = pos00;
                                this.cdata.business_logic['lid'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('lid') ? this.$store.state.state_tables.state_auth.r_auth['lid'] : "-1";
                                
    

                                //выставим награду
                                for (const [stopochka_key, stopochka] of Object.entries(this.cdata.business_logic['desk'])) {
                                //for (const [stopochka_key, stopochka] of Object.entries(this.cdata.business_logic['desk'])) {
                                    let reward = stopochka.reward;
                                    //выставим награду для карточек в стопочке
                                    for (const [card_key, card] of Object.entries(stopochka.card_list)) {
                                        card['reward'] = reward;
                                    }
                                }




                                //РАССЧЕТ ВЫУЧЕННОСТИ - ГОТОВИМ 2 СПИСКА
                                //this.cdata.business_logic['list_cards_notlearned_themes']
                                //this.cdata.business_logic['list_cards_learned_themes']
                                this.$root._CARD_LOGIC().make_list_cards_learned_or_not(this.$store, this.cdata);

                                //ВЫБОР КАРТОЧКИ!
                                this.cdata.business_logic.curr_card.stopochka_num = -1;
                                this.cdata.business_logic.curr_card.card_num = -1;
                                this.$root._CARD_LOGIC().choose_next_card_stopochkanum_and_cardnum(this.$store, this.cdata);

                                //ПОДАЧА КАРТОЧКИ! 
                                if (this.cdata.business_logic.curr_card.stopochka_num.toString() !== "-1" && this.cdata.business_logic.curr_card.card_num.toString() !== "-1")
                                {
                                    this.$root._CARD_LOGIC().get_card(this.$store, this.cdata, this.cdata.business_logic.curr_card.stopochka_num, this.cdata.business_logic.curr_card.card_num);
                                }
                                
                                
                                

//                                REWARDS
//                        // GOLD
//                        let gold_value = 0;
//                        let gold_multiplier = ((this.state["desks"][this.cdata.business_logic['desk_name']].solved_cards < 3) ? 2 : 1);
//                        for (const [desk_index, desk] of Object.entries(this.$store.state.state_tables.state_game_settings.level_props__gold_by_tasks.layouts["class_" + child_classnum].layout[child_lid])) {
//                            let desk_name = "json_" + desk.desk_id;
//                            if(desk_name == this.cdata.business_logic['desk_name']){
//                                gold_value = desk.gold_per_task;
//                            }
//                        }
//                        this.cdata.business_logic['card_gold'] = (Number(gold_value)) * gold_multiplier;
//                        // GOLD
//                        
//                        //EXP
//                        let exp_value = 0;
//                        let exp_multiplier = 1;
//                        
//                        for (const [task_index, task_data] of Object.entries(this.cdata.business_logic.curr_card.task_list)) {
//                            if(Object.keys(this.cdata.business_logic.rg_learning).length == 0) { exp_multiplier = this.$store.state.state_tables.state_game_settings.level_props__exp_by_tasks.layouts["class_" + this.$store.state.state_tables.state_auth.r_auth.classnum].multiplier_value; }
//                            else {
//                                for (const [theme_index, theme_data] of Object.entries(this.cdata.business_logic.rg_learning)) {
//                                    if(theme_data.theme_id == task_data.theme_id){
//                                        if(theme_data.theme_learning_task_count < theme_data.theme_learning_task_limit){ exp_multiplier = this.$store.state.state_tables.state_game_settings.level_props__exp_by_tasks.layouts["class_" + this.$store.state.state_tables.state_auth.r_auth.classnum].multiplier_value; }
//                                        break;   
//                                    } else {
//                                        exp_multiplier = this.$store.state.state_tables.state_game_settings.level_props__exp_by_tasks.layouts["class_" + this.$store.state.state_tables.state_auth.r_auth.classnum].multiplier_value;
//                                        break;
//                                    }
//                                }
//                            }
//                        }
//                        
//                        for (const [desk_index, desk] of Object.entries(this.$store.state.state_tables.state_game_settings.level_props__exp_by_tasks.layouts["class_" + child_classnum].layout[child_lid])) {
//                            let desk_name = "json_" + desk.desk_id;
//                            if(desk_name == this.cdata.business_logic['desk_name']){
//                                exp_value = desk.exp_per_task;
//                            }
//                        }
//                        this.cdata.business_logic['card_exp'] = (Number(exp_value)) * exp_multiplier;
//                        //EXP

//                                REWARDS






                                  console.log("REGISTER ALL STAGE LOAD FIN - FOR PAGE [" + this.cdata.system._route_ + "]");
                                  this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page',   {'is_all_fin_for_this_page': true, '_route_': this.cdata.system._route_ });


                                  let choosecard_audio = new Audio('/resource/sound/choosecard.mp3');
                                    choosecard_audio.volume = 0.2;
                                    choosecard_audio.play();
                            }


                        }


                    }


                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после загрузки всех стадий для страницы, на любое действие с таблицами
                    if (this.$store.state.system[cdata.system._route_].is_all_fin_for_this_page)
                    {
                        //просто постоянно перещитываем счетчики и блокровки кнопок!
                        this.$root._SYS().watch_force_called_on_mounted_execute(this.$store, this.cdata, true);
                    }



                },
                deep: true, //чтобы детектил изменения вложенных в объекто элементов
                immediate: true, //При передаче immediate: true в опциях, коллбэк будет вызываться незамедлительно с текущим значением отслеживаемого выражения:
            },

        },

        methods: {
            
//            getImageWidth: function(imgUrl) {
//                let image = new Image();
//                image.onload = function () {
//                    
//                };
//                image.src = this.cdata['system']['cnf']['server_cloud'] + imgUrl;
//            },

            close_tips: function (event) {


//                this.cdata.business_logic.card_mode = "try_solve";
                this.cdata.business_logic.show_tips = false;

            },

            show_tips: function (event) {

                //let theme_hint_image = event.target.getAttribute('theme_hint_image');
                //let theme_hint_audio = event.target.getAttribute('theme_hint_audio');
                this.cdata.business_logic.tips = {};
                this.cdata.business_logic.tips.theme_hint_image = event.target.getAttribute('theme_hint_image');
                this.cdata.business_logic.tips.theme_hint_audio = event.target.getAttribute('theme_hint_audio');

                console.log(this.cdata.business_logic.tips.theme_hint_image);
                console.log(this.cdata.business_logic.tips.theme_hint_audio);
//                this.cdata.business_logic.card_mode = "show_tips";
                this.cdata.business_logic.show_tips = true;

            },

            slide_card: function (event) {

                this.cdata.business_logic.card_mode = "wait_screen";
                
                
                this.$store.dispatch('_business_logic/vuex_add__slide', this.$root._UPDATE().on_slide_add(this.$store, this.cdata));

                

                //РАССЧЕТ ВЫУЧЕННОСТИ - ГОТОВИМ 2 СПИСКА
                //this.cdata.business_logic['list_cards_notlearned_themes']
                //this.cdata.business_logic['list_cards_learned_themes']
                this.$root._CARD_LOGIC().make_list_cards_learned_or_not(this.$store, this.cdata);

                //ВЫБОР КАРТОЧКИ!
                this.cdata.business_logic.curr_card.stopochka_num = -1;
                this.cdata.business_logic.curr_card.card_num = -1;
                this.$root._CARD_LOGIC().choose_next_card_stopochkanum_and_cardnum(this.$store, this.cdata);

               //ПОДАЧА КАРТОЧКИ! 
                if (this.cdata.business_logic.curr_card.stopochka_num.toString() !== "-1" && this.cdata.business_logic.curr_card.card_num.toString() !== "-1")
                {
                    this.$root._CARD_LOGIC().get_card(this.$store, this.cdata, this.cdata.business_logic.curr_card.stopochka_num, this.cdata.business_logic.curr_card.card_num);
                    this.cdata.business_logic.curr_card_is_solved = false;
                    this.cdata.business_logic.card_mode = "try_solve";
                } 
                else
                {
                    this.cdata.business_logic.card_mode = "form_end_cards";
                }

                

            },

            next_card: function (event) {
                this.cdata.business_logic.card_mode = "wait_screen";
                this.cdata.business_logic.show_answer_style = "";
                
                if(!this.state.desks_limit[this.form.current_deskweek]['block_by_limit']){
                    //РАССЧЕТ ВЫУЧЕННОСТИ - ГОТОВИМ 2 СПИСКА
                    //this.cdata.business_logic['list_cards_notlearned_themes']
                    //this.cdata.business_logic['list_cards_learned_themes']
                    this.$root._CARD_LOGIC().make_list_cards_learned_or_not(this.$store, this.cdata);

                    //ВЫБОР КАРТОЧКИ!
                    this.cdata.business_logic.curr_card.stopochka_num = -1;
                    this.cdata.business_logic.curr_card.card_num = -1;
                    this.$root._CARD_LOGIC().choose_next_card_stopochkanum_and_cardnum(this.$store, this.cdata);

                    //ПОДАЧА КАРТОЧКИ! 
                    if (this.cdata.business_logic.curr_card.stopochka_num.toString() !== "-1" && this.cdata.business_logic.curr_card.card_num.toString() !== "-1")
                    {
                        this.$root._CARD_LOGIC().get_card(this.$store, this.cdata, this.cdata.business_logic.curr_card.stopochka_num, this.cdata.business_logic.curr_card.card_num);
                        this.cdata.business_logic.curr_card_is_solved = false;
                        this.cdata.business_logic.card_mode = "try_solve";
                    } 
                    else
                    {

                        this.cdata.business_logic.card_mode = "form_end_cards";

                    }
                } else {
//                    LIMIT REACHED
//                    
                    //LIMIT REACHED NOTIFIFY
                    this.$snotify.warning("You've reached daily limit in this Deck!", 'Attention', {
                        timeout: 3000,
                        showProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                    });
                    //END LIMIT REACHED NOTIFIFY
                    
                    this.$router.push('/g1/desksweek/' + '?t=' + this.$root._SYS().add_v());
                }
            },

            show_answers: async function (event) {

                console.log("START SHOW ANSWERS!!!");

                this.cdata.business_logic.curr_card_is_skiped = false;
                this.cdata.business_logic.curr_card_is_solved = this.$root._CARD_LOGIC().check_is_card_solved(this.$store, this.cdata);

                await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().on_card_solved__multi_ls_and_back(this.$store, this.cdata, this.state.quest_manager, this.state.player_current_profession, this.$snotify, this.state.lvl_info, this.state.player_inventory));

                //можно посмотреть в локальном хранилище vuex что пришло в answer
                console.log('flag state.answer');
                console.log(this.$store.state.answer);
                
                await this.$store.dispatch('_business_logic/vuex_clear__slide', {} );
                           

                this.cdata.business_logic.card_mode = "form_answers";
                
                if(this.cdata.business_logic.curr_card_is_solved){
                    this.cdata.business_logic.show_answer_style = "background: #C7FFC2; padding: 15px 115px;";
                    
                    let choosecard_audio = new Audio('/resource/sound/correct.mp3');
                        choosecard_audio.volume = 0.2;
                        choosecard_audio.play();
                } else {
                    this.cdata.business_logic.show_answer_style = "background: #FFE3E3; padding: 15px 115px;";
                }

            },

            focus_next_input: function (event) {

                // console.log(event.target.getAttribute('index'));

                const inputs = Array.from(document.querySelectorAll('input[type="number"]'));
                const index = inputs.indexOf(event.target);
                console.log(inputs);


                if (index < inputs.length - 1) {

                    inputs[index + 1].focus();
                    console.log(inputs[index + 1]);
                } else
                {
                    if (index === inputs.length - 1)
                    {
                        const solvebutton = document.getElementsByClassName('solvebutton');
                        console.log(solvebutton);
                        solvebutton[0].focus();
                    }
                }



            },

            window_str_pos00: function (current_deskweek) {
                return window._STR().pos00(current_deskweek);
            },

            move_to_desk: function (new_route) {

                //let current_deskweek = this.form.current_deskweek;//event.target.getAttribute('desk');
                this.cdata.business_logic.curr_card_is_solved = false;
                this.cdata.business_logic.card_mode = "try_solve";

                /*if (window._STR().is_not_empty(current_deskweek))
                 {*/
                this.$root._PAGE().move_to(this.$store, this.cdata, new_route);
                /* }*/

            },

            hide_center: function (new_route) {
                // this.cdata.system.is_section.center=false;
                //перед переходом на ДРУГУЮ СТРАНИЦУ!!! скрываем на loading... потому что при возврате, будет видны старые данные 
                //- помаргивание

                this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page',   {'is_all_fin_for_this_page': false, '_route_': this.cdata.system._route_ });
                this.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': false, '_route_': this.cdata.system._route_ });

                //?вроде не нужны флажечки... this.$root._SYS().watch_force_called_on_mounted_execute(this.$store, this.cdata);

                //new_route='/g1/empty2/'
                this.$router.push(new_route + '?t=' + this.$root._SYS().add_v());
            },
            //load переименовать 

            test_btn: async function () {

                console.log("TEST BTN");

                await this.$store.dispatch('_data/vuex_set__a_data__ls_or_back', this.$root._LOAD().a_test__ls_or_back(this.$store, this.cdata));

                console.log("TEST BTN FIN");
            },

        }


    }
</script>

<style scoped>
    @media (max-width: 991px){
        .page-body-wrapper {
            padding-top: 0px !important;
        }
    }
    
    
   
    
    
    
    
    
    

.checkbox-tools:checked + label,
.checkbox-tools:not(:checked) + label{
	position: relative;
	display: inline-block;
	padding: 10px 24px;
        background: #F4F5FD;
        border: 2px solid #DCE0EA;
        border-radius: 12px;
	text-align: center;
	cursor: pointer;
	text-transform: uppercase;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.checkbox-tools:not(:checked) + label{
        color: #2E3B4B;
}
.checkbox-tools:checked + label{
        background: #DDF4FF;
        border: 2px solid #49BDF4;
        color: #49BDF4;
}
.checkbox-tools:not(:checked) + label:hover{
	/*box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);*/
        /*background: #DDF4FF;*/
        /*background: #DCE0EA;*/
        
        border-color: #49BDF4;
        
}
.checkbox-tools:checked + label::before,
.checkbox-tools:not(:checked) + label::before{
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	z-index: -1;
}
.checkbox-tools:checked + label,
.checkbox-tools:not(:checked) + label{
        font-weight: 600;
        width: max-content;
        white-space: nowrap;
}
    

.checkbox-tools:checked,
.checkbox-tools:not(:checked){
	position: absolute;
	left: -9999px;
	width: 0;
	height: 0;
	visibility: hidden;
}

    .noselect{
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
        pointer-events: none;
    }
    
    .input_correct_answer {
            display:none;
            padding: 8px 10px;
            background: #C7FFC2;
            border-radius: 12px;
            
            /*display: flex;*/
            align-items: center;
    }
    
    .input_correct_answer p {
            font-weight: 600;
    }
    
    .input_text_answer {
            min-width: 87px; width: 20%; max-width: 120px; border: 2px solid #DCE0EA; border-radius: 12px; padding: 12px 16px;
    }
    
    
    .custom-modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1050;
        width: 100%;
        height: 100%;
        outline: 0;
        background: rgb(244, 245, 253);
    }
    
    
    .bottom_button_section{
        border-radius: 24px 24px 0px 0px; 
        /*padding: 40px 115px;*/
        /*padding: 30px 115px;*/
        padding: 39px 115px;
    } 
    
    .bottom_button_section img {
        width: 24px;
        height: 24px;
    }
    
    .result_test_correct {
        font-weight: 600;
        font-size: 28px;
        line-height: 30px;
        text-align: center;
    }
    
    .result_test_incorrect {
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
    }
    
    .tips_btn {
        background: #F4F5FD;
        box-shadow: 0px 2px 0px #DCE0EA;
        border-radius: 12px;
        padding: 0px 10px;
        font-size: 30px;
        cursor: pointer;
    }
    
    input:focus {
            border: 2px solid #F7D45A;
    }
    
    
.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}



.two_images_horisontal_container {
    height: 320px;
}

.images_container {
    height: 120px;
}

.tips_btn_outer {
/*    position: absolute;
    right:0;*/
    width: 44px;
    height: 44px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.task_text{
    font-size: 20px;
}

.task_text_left {
    font-weight: 500;
    font-size: 28px;
    color: #2E3B4B;
}

.one_image_container {
    height: 360px;
}

.responsive_image_workaround {
    min-width: 700px;
}

@media (max-height: 940px){
    .two_images_horisontal_container {
        height: 270px;
    }
}

@media (max-height: 895px){
    .two_images_horisontal_container {
        height: 210px;
    }
    
    .one_image_container {
        height: 340px;
    }
}

@media (max-height: 825px){
    .two_images_horisontal_container {
        height: 150px;
    }
    
    .one_image_container {
        height: 300px;
    }
}

@media (max-height: 770px){
    .two_images_horisontal_container {
        height: 100px;
    }
    .one_image_container {
        height: 250px;
    }
}

@media (max-width: 1450px){
    .result_test_correct {
        font-size: 20px;
    }

    .result_test_incorrect {
        font-size: 16px;
    }
}

@media (max-width: 880px){
    .responsive_image_workaround {
        min-width: 550px;
    }
}

@media (max-width: 700px){
    .tips_btn_outer {
        position: initial;
        margin-left: 1em;
    }
    .input_correct_answer {
        display:none !important;
    }
    .responsive_image_workaround {
        min-width: 375px;
    }
}

@media (max-width: 500px){
    .task_text_left {
        font-size: 20px;
    }
    .task_text{ 
        font-size: 14px;
    }
    .bottom_button_section {
        margin-top: 2em;
        font-size: 12px;
        padding: 40px 0px;
    }
    
    .bottom_button_section button {
        font-size: 14px;
        padding: 10px 30px;
    }
    
    .result_test_incorrect {
        font-size: 14px;
    }
    .input_correct_answer p {
        font-size: 14px;  
    }
    .responsive_image_workaround {
        min-width: initial;
        width: 100%
    }
}

</style>
